import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Slider from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import FlavorSlider from "../components/flavorSlider"
import Layout from "../components/layout"
import People from "../components/people"
import SEO from "../components/seo"

const CarouselAction = ({ children }) => (
  <div className="bg-white hover:bg-blue-700 hover:text-white shadow-lg rounded-full flex items-center justify-center h-16 w-16 text-3xl cursor-pointer">
    {children}
  </div>
)

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query MenuItems {
      allSanityMenuItem(filter: { spotlight: { eq: true } }) {
        nodes {
          id
          name
          description {
            children {
              text
            }
          }
          image {
            asset {
              fixed(width: 200, height: 200) {
                ...GatsbySanityImageFixed
              }
            }
          }
        }
      }

      allSanityFlavors(sort: { fields: heat }) {
        nodes {
          heat
          id
          description
          name
        }
      }
    }
  `)

  const slickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoPlay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  }

  return (
    <>
      <Layout>
        <SEO title="Home" />

        <div className="flex overflow-hidden">
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
          <img src="https://nolocdnmsftaznua.azureedge.net/Content?ContentFile=header.png&companyCode=LWAG001&designId=1&v=665" />
        </div>
        {data.allSanityMenuItem.nodes.length && (
          <div className="flex justify-center mt-4 overflow-hidden">
            <div className="container p-6">
              <Slider {...slickSettings} className="">
                {data.allSanityMenuItem.nodes.map((item, i) => {
                  return (
                    <div className="h-full p-4 text-center text-gray-800 flex flex-col justify-center items-center">
                      {item.image && (
                        <Img
                          className="rounded-lg shadow-md mb-4"
                          fixed={item.image.asset.fixed}
                        />
                      )}
                      <h1 className="font-bold text-2xl italic leading-loose tracking-wide">
                        {item.name}
                      </h1>
                      <p className="slide-desc text-sm h-10">
                        {item.description?.map(desc => {
                          return desc.children?.map(child => {
                            return child.text
                          })
                        })}
                      </p>
                      <a
                        href="https://leftyswingsandgrill.alohaorderonline.com/"
                        className="bg-blue-600 hover:bg-blue-500 rounded shadow-lg text-white px-6 w-full py-4 mt-6 font-bold text-2xl block"
                      >
                        Order Takeout{" "}
                        <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                      </a>
                    </div>
                  )
                })}
              </Slider>
            </div>
          </div>
        )}
        <div className="bg-blue-800 mt-20 flex justify-center p-4">
          <div className="container text-white p-4 flex flex-col justify-center items-center">
            <div className="leading-9 text-2xl max-w-3xl">
              <blockquote className="relative italic">
                <svg
                  class="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-white opacity-50"
                  fill="currentColor"
                  viewBox="0 0 32 32"
                  aria-hidden="true"
                >
                  <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                </svg>
                Always a pleasure to stop in and grab a bite. Wonderful staff
                with great knowledge of the menu and great atmosphere. A place
                where friends and family and large groups can get together and
                have a good time.
              </blockquote>
              <p className="text-right font-bold">- Owen C.</p>
            </div>
            <div>
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <FontAwesomeIcon icon={faStar} />
              <a
                target="_new"
                href="https://www.google.com/search?q=leftys+wings+and+grill&oq=leftys+wings+and+grill#lrd=0x88dee8fcd7fcffad:0x89c919a73a6424a2,1,,,"
              >
                {" "}
                -{" "}
                <span className="font-bold underline text-xl text-yellow-300">
                  Read More Google Reviews
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className="mt-20 flex justify-center p-4">
          <div className="container text-gray-800 p-4 flex justify-center">
            <div className="my-20 flex items-center justify-center">
              <div className="bg-white rounded-lg p-2 shadow-lg">
                <People />
              </div>
              <div className="p-12 md:w-1/2 text-center font-bold text-2xl text-gray-600">
                <h2 className="text-3xl tracking-wide leading-9 mb-4 text-gray-800">
                  About Us
                </h2>
                Lefty’s is a family friendly sports themed restaurant and bar.
                Celebrating over 20 years as a community favorite restaurant.
                Our wings have won many awards as St. Lucie's Best Wings!
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 flex justify-center p-4 bg-blue-800">
          <div className="container text-white p-4 flex justify-center">
            <FlavorSlider data={data.allSanityFlavors.nodes} />
          </div>
        </div>
      </Layout>
    </>
  )
}

export default IndexPage
