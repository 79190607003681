import {
  faArrowRight,
  faFireAlt,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React, { useState } from "react"

// TODO:
// - slider with heat on it

const FlavorSlider = ({ data }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const hasNextIndex = data.length - 1 > currentIndex
  const hasPreviousIndex = currentIndex - 1 >= 0
  const current = data[currentIndex]
  const heat = Math.trunc(current.heat)

  const onNext = () => {
    if (hasNextIndex) {
      const nextIndex = currentIndex + 1
      setCurrentIndex(nextIndex)
    }
  }

  const onPrevious = () => {
    if (hasPreviousIndex) {
      const prevIndex = currentIndex - 1
      setCurrentIndex(prevIndex)
    }
  }

  return (
    <div className="md:w-2/3">
      <h1 className="text-4xl font-black uppercase leading-snug tracking-wide text-center">
        Incredible Wing Flavors
      </h1>
      <p className="text-lg font-semibold leading-relaxed mb-12 text-center">
        Explore our saucy or dry rub flavors that range from mild to hot, in
        sweet or savory.
      </p>
      <div className="flex items-center mb-1">
        <button
          onClick={onPrevious}
          className="flex-shrink-0 select-none hover:bg-white hover:text-gray-800 flex-shrink-0 border-2 rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faMinus} />
        </button>
        <div className="border w-full mr-4 ml-4"></div>
        <button
          onClick={onNext}
          className="flex-shrink-0 select-none hover:bg-white hover:text-gray-800 border-2 rounded-full w-10 h-10 flex justify-center items-center cursor-pointer"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>
      <div className="flex justify-between mb-12">
        <div className="ml-1 select-none">less</div>
        <div className="select-noen">more</div>
      </div>
      <div className="text-center mb-6">
        <h1 className="text-2xl font-semibold tracking-wide">{current.name}</h1>
      </div>
      <div className="text-center mb-6 text-xl font-medium">
        {current.description}
      </div>
      <div className="text-4xl text-center mb-6">
        {Array(heat)
          .fill()
          .map((_, i) => (
            <FontAwesomeIcon
              key={`fire-${i}`}
              icon={faFireAlt}
              className={`${
                heat !== 5 ? "text-yellow-600" : "text-red-600"
              } mr-2`}
            />
          ))}
        {heat < 5 &&
          Array(5 - heat)
            .fill()
            .map((_, i) => (
              <FontAwesomeIcon
                key={`dud-${i}`}
                icon={faFireAlt}
                className="mr-2 text-white"
              />
            ))}
      </div>
      <div className="flex justify-center">
        <a
          href="/menu"
          className="rounded bg-white font-bold text-3xl flex items-center justify-center p-8 h-12 text-gray-800 hover:bg-gray-50 shadow-lg"
        >
          Browse our menu{" "}
          <FontAwesomeIcon className="ml-1" icon={faArrowRight} />
        </a>
      </div>
    </div>
  )
}

export default FlavorSlider
